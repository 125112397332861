import { useState } from "react";
import { Button, TextField } from "@hyperlocal/vital2";
import { withMask } from "use-mask-input";

const CODE_LENGTH = 6;

type MFAValidationProps = {
  onSuccess: (code: string) => void;
  isLoading?: boolean;
};

export const MFAValidation = ({ onSuccess, isLoading }: MFAValidationProps) => {
  const [code, setCode] = useState("");

  const handleConfirm = async () => {
    onSuccess(code);
  };

  return (
    <div className="flex h-full flex-col justify-between p-[16px]">
      <div className="flex flex-col gap-[16px]">
        <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
          Informe o código de autenticação
        </h5>

        <div className="flex gap-2">
          <span>●</span>
          <span>
            Abra seu aplicativo Google Authenticator e insira o código de seis
            dígitos exibido no aplicativo.
          </span>
        </div>

        <TextField.Label htmlFor="totp" className="mt-inset-md">
          Digite o código
        </TextField.Label>

        <TextField.Root>
          <TextField.Input
            id="totp"
            type="text"
            ref={withMask("999999")}
            value={code}
            onChange={({ target }) => setCode(target.value)}
          />
        </TextField.Root>
      </div>

      <Button.Root
        className="mt-auto"
        disabled={code.length !== CODE_LENGTH}
        isLoading={isLoading}
        onClick={handleConfirm}
      >
        Verificar
      </Button.Root>
    </div>
  );
};
