import { useEffect, useState } from "react";
import { useFavoredList } from "@/pages/settings/react-query/favored-list";
import { useGetBanks } from "@/pages/settings/react-query/get-banks";
import { NewFavoredSheet } from "@/pages/settings/sections/favored/components/new-favored-sheet";
import { Table } from "@/pages/settings/sections/favored/components/table";
import { TablePagination } from "@/pages/settings/sections/favored/components/table-pagination";
import {
  chunkArray,
  getLastUpdate,
} from "@/pages/settings/sections/team-access/team-access";
import { Hooks, setHeader } from "@/utils";
import { useNavigate } from "react-router-dom";

const ROWS_PER_PAGE = 10;

export function Favored() {
  const { favoredList, updatedAt, isLoading } = useFavoredList();
  const [pageNumber, setPageNumber] = useState(1);
  const [lastUpdate, setLastUpdate] = useState("");
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();

  useGetBanks();

  useEffect(() => {
    // Atualiza a cada 1 segundo
    const intervalId = setInterval(() => {
      setLastUpdate(getLastUpdate(updatedAt));
    }, 1000);

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, [updatedAt]);

  useEffect(() => {
    if (!isMobile) return;
    setTimeout(() => {
      setHeader({
        title: "Favorecidos",
        show: isMobile,
        leftIcon: {
          name: "ArrowArrowNoLineLeft",
          onClick: () => navigate(-1),
        },
      });
    }, 50);
  }, [isMobile, navigate]);

  const pages = chunkArray(favoredList, ROWS_PER_PAGE);
  const pagesCount = pages.length;
  const currentPage = isMobile ? favoredList : pages[pageNumber - 1];

  return (
    <div className="flex h-full flex-col">
      <div className="-mr-6 flex flex-1 flex-col justify-between overflow-hidden mobile:!mr-0">
        <Table
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageData={currentPage}
          isLoading={isLoading}
        />

        <div className="flex items-center justify-between pr-6 mobile:!hidden mobile:!pr-0">
          {isLoading ? (
            <div className="block h-[21px] w-[280px] animate-pulse rounded-sm bg-neutral-lighter" />
          ) : (
            <span className="text-x2s/md text-neutral-darkest">
              {lastUpdate || getLastUpdate(updatedAt)}
            </span>
          )}

          <TablePagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pagesCount={pagesCount}
            isLoading={isLoading}
          />
        </div>
      </div>

      <div className="mt-4 hidden flex-col mobile:!flex">
        <NewFavoredSheet />
      </div>
    </div>
  );
}
