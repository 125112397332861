import { DeviceStatus } from "@/pages/settings/sections/trusted-devices/use-device-management";
import {
  deviceQueryFactory,
  useAccountStore,
  type Device,
  type DeviceStatusType,
  type ListDeviceUseCaseSuccessResponse,
} from "@/utils/utility";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { useEffect } from "react";

interface UseListDevicesInput {
  page?: string;
  itemsPerPage?: string;
  status?: DeviceStatusType;
  enabled?: boolean;
}

type UseListDevicesOutput = {
  error: Error | null;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => Promise<unknown>;
} & ListDeviceUseCaseSuccessResponse;

const defaultPaginationData: ListDeviceUseCaseSuccessResponse = {
  currentPage: 0,
  pageCount: 0,
  pageSize: 0,
  rowCount: 0,
  firstRowOnPage: 0,
  lastRowOnPage: 0,
  results: [],
};

export function useListDevices({
  itemsPerPage,
  page,
  status,
  enabled = true,
}: UseListDevicesInput = {}): UseListDevicesOutput {
  const personId = useAccountStore(
    (state) =>
      state.account?.personInfoResponse?.personId ||
      state.account?.representativeInfoResponse[0]?.personId,
  );

  const query = useQuery(
    deviceQueryFactory.list({
      itemsPerPage,
      personId,
      page,
      status,
      enabled,
    }),
  );

  return {
    ...(query.data ?? defaultPaginationData),
    error: query.error,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    refetch: async () => {
      await query.refetch();
    },
  };
}

interface UseInfiniteListDevicesInput {
  itemsPerPage?: string;
  status?: DeviceStatus;
  enabled?: boolean;
}

type UseInfiniteListDevicesOutput = {
  error: Error | null;
  isLoading: boolean;
  isFetching: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<unknown>;
  refetch: () => Promise<unknown>;
} & ListDeviceUseCaseSuccessResponse;

export function useInfiniteListDevices({
  itemsPerPage,
  status,
  enabled,
}: UseInfiniteListDevicesInput = {}): UseInfiniteListDevicesOutput {
  const personId = useAccountStore(
    (state) =>
      state.account?.personInfoResponse?.personId ||
      state.account?.representativeInfoResponse[0]?.personId,
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.removeQueries({
      queryKey: [...deviceQueryFactory.default, "infinite-list"],
    });
  }, [status, queryClient]);
  const query = useInfiniteQuery<ListDeviceUseCaseSuccessResponse>({
    ...deviceQueryFactory.infiniteList({
      itemsPerPage,
      personId,
      status,
      enabled,
    }),
    select: (data) => {
      const filteredPages = data.pages.map((page) => ({
        ...page,
        results: page.results.filter((device) =>
          status
            ? device.deviceStatus === status
            : device.deviceStatus !== "deleted",
        ),
      }));
      return {
        ...data,
        pages: filteredPages,
      };
    },
  });

  const allResults = query.data?.pages.flatMap((page) => page.results) ?? [];
  const lastPage = query.data?.pages[query.data.pages.length - 1];

  return {
    ...(lastPage ?? defaultPaginationData),
    results: allResults,
    error: query.error,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    hasNextPage: query.hasNextPage,
    fetchNextPage: async () => {
      await query.fetchNextPage();
    },
    refetch: async () => {
      await query.refetch();
    },
  };
}
