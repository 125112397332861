import { useState } from "react";
import { useListTaxReports } from "@/api/tax-income/list-tax-reports";
import { useRequestTaxIncome } from "@/api/tax-income/use-request-tax-income";
import { Spinner } from "@/components/spinner";
import { hasPermission } from "@/utils";
import { editEquipAccess } from "@/utils/allowed-roles";
import { Button, Select } from "@hyperlocal/vital2";

export const IncomeTax = () => {
  const { isLoading, isFetching, data, isError } = useListTaxReports();

  const reportData = data?.reports || [];

  const [reportCode, setReportCode] = useState("");

  const requestTaxIncomeMutation = useRequestTaxIncome();

  const onRequest = async () => {
    await requestTaxIncomeMutation.mutateAsync({ reportCode });
  };

  const shouldBeAbletoRequest = hasPermission(editEquipAccess) && !!reportCode;

  const isLoadingReports = isLoading || isFetching;

  if (isLoadingReports) return <Spinner />;

  return (
    <div className="flex h-full flex-col items-end justify-between mobile:items-start">
      <div className="flex h-full w-full flex-col">
        <h5 className="mb-4 text-sm/md font-bold text-neutral-darkest mobile:!text-xs/md">
          Relatório de IR
        </h5>
        {isError ? (
          <span>Não foram encontrados relatórios para esta conta.</span>
        ) : (
          <>
            <span>
              Selecione o período desejado e o arquivo será enviado para o seu
              e-mail de acesso a conta digital.
            </span>
            <div className="mt-6 w-full">
              <Select.Root value={reportCode} onValueChange={setReportCode}>
                <Select.Label>Período</Select.Label>
                <Select.Trigger className="w-full max-w-[50%] hover:cursor-pointer disabled:cursor-not-allowed mobile:max-w-[374px]">
                  <Select.Value placeholder="Selecione" />
                </Select.Trigger>
                <Select.Content>
                  {reportData.map((report) => (
                    <Select.Item
                      key={report.reportCode}
                      value={report.reportCode}
                    >
                      {report.reportCode}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </div>
            <Button.Root
              onClick={onRequest}
              isLoading={requestTaxIncomeMutation.isPending}
              className="mt-auto self-end mobile:w-full"
              disabled={!shouldBeAbletoRequest}
            >
              Solicitar
            </Button.Root>
          </>
        )}
      </div>
    </div>
  );
};
