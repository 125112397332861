import apis from "@/services";
import { getDashboardRoutesData } from "@/utils";
import { FIVE_SECONDS } from "@/utils/constants";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

const FIVE_MINUTES = 5 * 60 * 1000;

function findTransactionById(transactionId: string) {
  return apis.getPendingTransactionById(transactionId);
}

async function listPendingTransactions() {
  try {
    return await apis.getPendingTransactions({ transactionStatus: "PENDING" });
  } catch (error) {
    return [];
  }
}

export const transactionQueryKeyFactory = {
  default: ["transactions"] as const,
  detail: (transactionId: string) =>
    [...transactionQueryKeyFactory.default, transactionId] as const,
  pending: () => [...transactionQueryKeyFactory.default, "pending"] as const,
};

export function useTransaction(transactionId: string, enabled = false) {
  const { data, isLoading } = useQuery({
    queryFn: () => findTransactionById(transactionId),
    queryKey: transactionQueryKeyFactory.detail(transactionId),
    staleTime: FIVE_MINUTES,
    enabled,
  });

  return { data, isLoading };
}

export function useSuspenseTransaction(transactionId: string) {
  const { data, isLoading } = useSuspenseQuery({
    queryFn: () => findTransactionById(transactionId),
    queryKey: transactionQueryKeyFactory.detail(transactionId),
    staleTime: FIVE_MINUTES,
  });

  return { data, isLoading };
}

export function usePendingTransactions() {
  const { pathname } = useLocation();

  const isPendingRoute =
    pathname === getDashboardRoutesData().pendingTransactions;

  const { data, isLoading } = useSuspenseQuery({
    queryFn: listPendingTransactions,
    queryKey: transactionQueryKeyFactory.pending(),
    refetchInterval: isPendingRoute ? FIVE_SECONDS : false,
  });

  return { data, isLoading };
}
