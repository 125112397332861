import { ComponentProps } from "react";

export function Eye(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 15.575c1.133 0 2.096-.396 2.887-1.188.792-.791 1.188-1.754 1.188-2.887s-.396-2.096-1.188-2.887c-.791-.792-1.754-1.188-2.887-1.188s-2.095.396-2.887 1.188c-.792.791-1.188 1.754-1.188 2.887s.396 2.096 1.188 2.887c.792.792 1.754 1.188 2.887 1.188zm0-1.375c-.75 0-1.387-.263-1.912-.788A2.601 2.601 0 019.3 11.5c0-.75.263-1.388.788-1.913A2.603 2.603 0 0112 8.8c.75 0 1.388.262 1.913.787.525.525.787 1.163.787 1.913s-.262 1.387-.787 1.912A2.605 2.605 0 0112 14.2zm0 4.3c-2.183 0-4.187-.575-6.013-1.725-1.824-1.15-3.22-2.708-4.187-4.675a.901.901 0 01-.075-.263 2.207 2.207 0 010-.675.9.9 0 01.075-.262c.967-1.967 2.363-3.525 4.187-4.675C7.813 5.075 9.817 4.5 12 4.5c2.184 0 4.188.575 6.012 1.725 1.825 1.15 3.221 2.708 4.188 4.675a.903.903 0 01.075.262 2.223 2.223 0 010 .675.904.904 0 01-.075.263c-.966 1.967-2.363 3.525-4.188 4.675C16.187 17.925 14.183 18.5 12 18.5zm0-1.5a9.545 9.545 0 005.188-1.488A9.77 9.77 0 0020.8 11.5a9.777 9.777 0 00-3.612-4.013A9.55 9.55 0 0012 6a9.55 9.55 0 00-5.188 1.487A9.777 9.777 0 003.2 11.5a9.77 9.77 0 003.612 4.012A9.545 9.545 0 0012 17z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Dots(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.094 18.327A2.091 2.091 0 0112 20.419a2.091 2.091 0 01-2.093-2.092c0-1.157.936-2.093 2.093-2.093s2.093.936 2.093 2.093zM14.094 5.512A2.091 2.091 0 0112 7.605a2.091 2.091 0 01-2.093-2.093c0-1.157.936-2.093 2.093-2.093s2.093.936 2.093 2.093zM14.094 11.827A2.091 2.091 0 0112 13.919a2.091 2.091 0 01-2.093-2.092c0-1.157.936-2.093 2.093-2.093s2.093.936 2.093 2.093z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DisplaySettings(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1077_4741)" fill="currentColor">
        <path d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 00-2-2zm0 14H4V5h16v12z" />
        <path d="M14 8.25H6v1.5h8v-1.5zM16.5 9.75H18v-1.5h-1.5V7H15v4h1.5V9.75zM18 12.25h-8v1.5h8v-1.5zM7.5 15H9v-4H7.5v1.25H6v1.5h1.5V15z" />
      </g>
      <defs>
        <clipPath id="clip0_1077_4741">
          <path fill="currentColor" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArrowLeft(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.475 17.125l-4.5-4.5a.822.822 0 01-.188-.288.935.935 0 010-.675.824.824 0 01.188-.287l4.5-4.5A.72.72 0 0114 6.65c.2 0 .375.075.525.225a.72.72 0 01.225.525.72.72 0 01-.225.525L10.45 12l4.075 4.075a.72.72 0 01.225.525.72.72 0 01-.225.525.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowRight(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.875 17.125a.72.72 0 01-.225-.525c0-.2.075-.375.225-.525L12.95 12 8.875 7.925A.72.72 0 018.65 7.4c0-.2.075-.375.225-.525A.72.72 0 019.4 6.65c.2 0 .375.075.525.225l4.5 4.5a.825.825 0 01.188.287.944.944 0 010 .675.823.823 0 01-.188.288l-4.5 4.5a.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowUp(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.875 14.525A.72.72 0 016.65 14c0-.2.075-.375.225-.525l4.5-4.5a.822.822 0 01.288-.188.935.935 0 01.675 0 .825.825 0 01.287.188l4.5 4.5a.72.72 0 01.225.525.72.72 0 01-.225.525.72.72 0 01-.525.225.72.72 0 01-.525-.225L12 10.45l-4.075 4.075a.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowDown(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.475 17.125l-4.5-4.5a.822.822 0 01-.188-.288.935.935 0 010-.675.825.825 0 01.188-.287l4.5-4.5A.72.72 0 0114 6.65c.2 0 .375.075.525.225a.72.72 0 01.225.525.72.72 0 01-.225.525L10.45 12l4.075 4.075a.72.72 0 01.225.525.72.72 0 01-.225.525.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseRounded(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a-close-rounded"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="currentColor" d="M0 0H24V24H0z" />
      </mask>
      <g mask="url(#a-close-rounded)" fill="currentColor">
        <path d="M12.002 21.5a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.017-2.03 9.591 9.591 0 01-2.032-3.016 9.245 9.245 0 01-.748-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.596 9.596 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.247 9.247 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.597 9.597 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749zM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20z" />
        <path d="M12.083 13.21l1.573 1.573c.145.145.32.217.527.217a.718.718 0 00.527-.217.707.707 0 00.212-.532.725.725 0 00-.212-.522l-1.573-1.573 1.573-1.573a.717.717 0 00.217-.527.717.717 0 00-.217-.527.707.707 0 00-.532-.213.725.725 0 00-.522.213l-1.573 1.573-1.573-1.573a.718.718 0 00-.527-.217.718.718 0 00-.527.217.707.707 0 00-.213.532c.004.21.074.383.213.522l1.573 1.573-1.573 1.573a.717.717 0 00-.217.527c0 .206.072.382.217.527a.707.707 0 00.532.212.725.725 0 00.522-.212l1.573-1.573z" />
      </g>
    </svg>
  );
}

export function Star(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.795 17.059l3.15-1.9 3.15 1.925-.825-3.6 2.775-2.4-3.65-.325-1.45-3.4-1.45 3.375-3.65.325 2.775 2.425-.825 3.575zm3.15-.125l-3.9 2.325a.742.742 0 01-.825-.025.85.85 0 01-.275-.337.63.63 0 01-.025-.463l1.025-4.4-3.425-2.975a.742.742 0 01-.225-.825.807.807 0 01.238-.338.734.734 0 01.412-.162l4.525-.4 1.775-4.175a.723.723 0 01.288-.338.787.787 0 01.412-.112c.15 0 .288.037.413.112.125.075.22.188.287.338l1.775 4.175 4.525.4a.739.739 0 01.65.5.734.734 0 01-.225.825l-3.425 2.975 1.025 4.4a.63.63 0 01-.025.463.85.85 0 01-.275.337.742.742 0 01-.825.025l-3.9-2.325z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Notifications(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.25 18.876v-1.5h2v-7.25c0-1.367.421-2.571 1.263-3.613A5.664 5.664 0 0110.75 4.5v-.7c0-.35.121-.646.363-.888.241-.242.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888v.7c1.317.3 2.396.97 3.238 2.012.841 1.042 1.262 2.246 1.262 3.613v7.25h2v1.5H4.25zM12 21.8c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0110.2 20h3.6c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525zm-4.25-4.425h8.5v-7.25c0-1.184-.413-2.188-1.238-3.013-.825-.825-1.829-1.237-3.012-1.237s-2.187.412-3.012 1.237c-.825.825-1.238 1.83-1.238 3.013v7.25z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Allowed(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.3 10.025c.067.317.117.642.15.975.033.333.05.667.05 1 0 1.317-.25 2.55-.75 3.7a9.655 9.655 0 01-2.038 3.012A9.655 9.655 0 0115.7 20.75c-1.15.5-2.383.75-3.7.75-1.317 0-2.55-.25-3.7-.75a9.667 9.667 0 01-3.013-2.038A9.664 9.664 0 013.25 15.7 9.178 9.178 0 012.5 12c0-1.317.25-2.55.75-3.7a9.676 9.676 0 012.037-3.013A9.676 9.676 0 018.3 3.25c1.15-.5 2.383-.75 3.7-.75.883 0 1.733.117 2.55.35a9.56 9.56 0 012.275.975.724.724 0 01.338.475c.041.2-.013.392-.163.575a.849.849 0 01-.5.313.753.753 0 01-.575-.113 7.064 7.064 0 00-1.85-.8A7.79 7.79 0 0012 4c-2.217 0-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20s4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12c0-.283-.017-.563-.05-.838a7.69 7.69 0 00-.15-.837.866.866 0 01.075-.463.785.785 0 01.3-.337c.233-.167.463-.192.688-.075.225.117.37.308.437.575zm-11.35 5.6L7.175 12.85a.653.653 0 01-.187-.513.736.736 0 01.212-.512c.15-.15.33-.225.538-.225.208 0 .379.075.512.225l2.325 2.325 9.375-9.375a.62.62 0 01.513-.213.695.695 0 01.512.238c.15.133.225.304.225.512a.734.734 0 01-.225.538l-9.75 9.775a.934.934 0 01-.65.25.848.848 0 01-.625-.25z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Denied(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="CloseCircle9.45268"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="currentColor" d="M0 0H24V24H0z" />
      </mask>
      <g mask="url(#CloseCircle9.45268)" fill="currentColor">
        <path d="M12.002 21.5a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749zM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20z" />
        <path d="M12.083 13.21l1.573 1.573c.145.145.32.217.527.217a.718.718 0 00.527-.217.707.707 0 00.212-.532.725.725 0 00-.212-.522l-1.573-1.573 1.573-1.573a.718.718 0 00.217-.527.718.718 0 00-.217-.527.707.707 0 00-.532-.213.724.724 0 00-.522.213l-1.573 1.573-1.573-1.573a.718.718 0 00-.527-.217.718.718 0 00-.527.217.707.707 0 00-.213.532c.004.21.074.383.213.522l1.573 1.573-1.573 1.573a.717.717 0 00-.217.527c0 .206.072.382.217.527a.707.707 0 00.532.212.725.725 0 00.522-.212l1.573-1.573z" />
      </g>
    </svg>
  );
}

export function Persons(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.8 19.3v-2.226c0-.516.133-.975.4-1.375s.625-.716 1.075-.95c.95-.45 1.904-.804 2.863-1.062.958-.258 2.012-.388 3.162-.388s2.204.13 3.163.388c.958.258 1.912.612 2.862 1.062.45.234.808.55 1.075.95.267.4.4.859.4 1.375V19.3h-15zm17 0v-2.35c0-.65-.163-1.272-.488-1.864a4.767 4.767 0 00-1.362-1.537c.667.1 1.3.254 1.9.462.6.209 1.175.455 1.725.738.517.284.917.613 1.2.988.283.375.425.779.425 1.212v2.35h-3.4zm-9.5-7.6c-.967 0-1.792-.347-2.475-1.039C6.142 9.97 5.8 9.15 5.8 8.2c0-.966.342-1.791 1.025-2.475A3.372 3.372 0 019.3 4.7c.967 0 1.792.342 2.475 1.025A3.372 3.372 0 0112.8 8.2c0 .95-.342 1.77-1.025 2.462-.683.692-1.508 1.038-2.475 1.038zm8.625-3.5c0 .95-.342 1.77-1.025 2.461-.683.692-1.508 1.038-2.475 1.038-.1 0-.238-.012-.413-.038a3.292 3.292 0 01-.437-.087c.4-.483.704-1.012.913-1.587A5.22 5.22 0 0014.8 8.2c0-.633-.108-1.233-.325-1.8a5.932 5.932 0 00-.9-1.575c.133-.05.275-.083.425-.1.15-.016.292-.025.425-.025.967 0 1.792.342 2.475 1.025A3.372 3.372 0 0117.925 8.2zM3.3 17.8h12v-.726a.99.99 0 00-.162-.55 1.302 1.302 0 00-.488-.425 13.024 13.024 0 00-2.55-.963c-.867-.224-1.8-.337-2.8-.337-1 0-1.937.113-2.812.337-.875.226-1.721.547-2.538.963-.233.117-.4.259-.5.425-.1.167-.15.35-.15.55v.725zm6-7.6c.55 0 1.021-.197 1.413-.589.391-.391.587-.862.587-1.412 0-.55-.196-1.02-.587-1.413A1.928 1.928 0 009.3 6.2c-.55 0-1.02.196-1.412.587A1.927 1.927 0 007.3 8.2c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Person(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 11.7c-.967 0-1.792-.347-2.475-1.039C8.842 9.97 8.5 9.15 8.5 8.2c0-.966.342-1.791 1.025-2.475A3.372 3.372 0 0112 4.7c.967 0 1.792.342 2.475 1.025A3.372 3.372 0 0115.5 8.2c0 .95-.342 1.77-1.025 2.462-.683.692-1.508 1.038-2.475 1.038zm-7.5 7.6v-2.226c0-.483.133-.933.4-1.35a2.76 2.76 0 011.075-.975 14.36 14.36 0 012.988-1.088A13.003 13.003 0 0112 13.3c1.017 0 2.03.12 3.038.362s2.004.605 2.987 1.088c.45.234.808.559 1.075.975.267.417.4.867.4 1.35V19.3h-15zM6 17.8h12v-.726c0-.2-.058-.383-.175-.55a1.476 1.476 0 00-.475-.425 14.004 14.004 0 00-2.637-.963 11.07 11.07 0 00-5.426 0c-.891.226-1.77.547-2.637.963-.2.117-.358.259-.475.425a.938.938 0 00-.175.55v.725zm6-7.6c.55 0 1.021-.197 1.413-.589C13.804 9.22 14 8.75 14 8.2c0-.55-.196-1.02-.587-1.413A1.928 1.928 0 0012 6.2c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0010 8.2c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SearchLupe(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.025 20.05L13.25 14.3c-.5.417-1.075.742-1.725.975-.65.233-1.317.35-2 .35-1.717 0-3.167-.592-4.35-1.775C3.992 12.667 3.4 11.217 3.4 9.5c0-1.7.592-3.146 1.775-4.338 1.183-1.191 2.633-1.787 4.35-1.787 1.7 0 3.142.592 4.325 1.775 1.183 1.183 1.775 2.633 1.775 4.35 0 .717-.117 1.4-.35 2.05a5.612 5.612 0 01-.95 1.7l5.775 5.775c.133.133.2.3.2.5a.72.72 0 01-.225.525.733.733 0 01-.537.225.655.655 0 01-.513-.225zm-9.5-5.925c1.283 0 2.37-.45 3.263-1.35.891-.9 1.337-1.992 1.337-3.275s-.446-2.375-1.337-3.275c-.892-.9-1.98-1.35-3.263-1.35-1.3 0-2.396.45-3.287 1.35C5.346 7.125 4.9 8.217 4.9 9.5s.446 2.375 1.338 3.275c.891.9 1.987 1.35 3.287 1.35z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Close(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 13.05l-5.075 5.075a.736.736 0 01-.512.212.69.69 0 01-.538-.212.72.72 0 01-.225-.525c0-.2.075-.375.225-.525L10.95 12 5.875 6.925a.737.737 0 01-.212-.513.69.69 0 01.212-.537.72.72 0 01.525-.225c.2 0 .375.075.525.225L12 10.95l5.075-5.075a.74.74 0 01.513-.213.693.693 0 01.537.213.72.72 0 01.225.525.72.72 0 01-.225.525L13.05 12l5.075 5.075a.735.735 0 01.212.512.69.69 0 01-.212.538.72.72 0 01-.525.225.72.72 0 01-.525-.225L12 13.05z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Pix(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.956 11.734a2.843 2.843 0 00-.841-1.836c-.73-.741-1.471-1.471-2.212-2.212a.365.365 0 00-.244-.089h-1.161c-.675-.022-1.228.221-1.693.697l-2.798 2.799c-.72.719-1.549.719-2.268 0-.951-.94-1.903-1.892-2.843-2.843-.343-.343-.741-.587-1.228-.62-.453-.033-.896-.022-1.35-.022-.088 0-.199.055-.265.11-.719.709-1.416 1.417-2.124 2.125a2.993 2.993 0 00-.863 1.637L2 11.856v.32s.033.1.044.156c.067.696.354 1.305.841 1.803.73.74 1.471 1.47 2.212 2.212a.329.329 0 00.222.089h.94c.664.022 1.228-.222 1.692-.697.93-.952 1.87-1.88 2.821-2.821.144-.133.299-.266.465-.354.586-.299 1.239-.177 1.736.32.963.963 1.925 1.914 2.877 2.877.453.453.984.686 1.626.675h1.194a.479.479 0 00.288-.122c.708-.697 1.405-1.405 2.113-2.113.376-.365.663-.796.796-1.316.067-.255.1-.52.155-.786v-.188s-.033-.1-.044-.155l-.022-.022zm-12.213.42c-.885.886-1.77 1.76-2.632 2.644-.255.255-.542.366-.907.388-.377.022-.642-.1-.896-.377-.465-.508-.974-.984-1.46-1.47-.808-.808-.808-1.87 0-2.689.564-.564 1.128-1.128 1.692-1.703.1-.089.199-.122.32-.133.609-.078 1.063.155 1.472.597.774.83 1.593 1.604 2.4 2.412.056.055.122.066.21.11-.121.122-.154.177-.21.222h.011zm10.432 1.151c-.575.586-1.15 1.162-1.737 1.737a.409.409 0 01-.299.122c-.254.022-.508 0-.763 0a.838.838 0 01-.586-.233c-.963-.962-1.936-1.925-2.898-2.887 0-.022 0-.022-.022-.033l-.056-.078c.056-.033.133-.055.188-.11.896-.896 1.792-1.781 2.677-2.688.221-.222.476-.354.797-.321.276 0 .575-.066.807.022.244.1.42.365.609.564.42.42.851.84 1.272 1.272.763.786.774 1.848 0 2.622l.01.011z"
        fill="currentColor"
      />
      <path
        d="M17.763 6.535c-1.206-1.194-2.411-2.411-3.617-3.617a2.832 2.832 0 00-1.217-.741c-.254-.077-.52-.122-.774-.177h-.321s-.089.033-.122.033c-.785.078-1.416.42-1.969.974C8.582 4.168 7.431 5.33 6.27 6.49l-.144.144.022.033h.089c.95-.022 1.725.343 2.378 1.018.918.929 1.836 1.836 2.754 2.754.354.343.62.343.963 0l2.82-2.82c.487-.498 1.063-.83 1.77-.919.3-.033.609-.033.93-.055a.583.583 0 00-.1-.122l.011.011zm-2.478-.553c-.354.288-.73.542-1.062.852-.74.72-1.46 1.45-2.179 2.18-.055.055-.11.11-.166.154-1.15-1.029-2.113-2.245-3.352-3.164 0-.022.023-.044.045-.055.74-.741 1.47-1.493 2.223-2.212.63-.609 1.637-.686 2.279-.089.796.73 1.504 1.538 2.256 2.312.023 0 0 .022-.044.044v-.022zM17.598 17.32c-.996.056-1.815-.32-2.511-1.028-.896-.93-1.804-1.814-2.722-2.721-.354-.365-.62-.365-.995 0-.93.929-1.87 1.87-2.799 2.81-.52.52-1.128.851-1.87.918a9.044 9.044 0 01-.586.044c.044.055.067.077.089.1l3.65 3.65c.343.332.73.597 1.195.73.287.088.586.122.874.188h.155c.055-.022.121-.033.177-.044a2.868 2.868 0 001.858-.841c1.239-1.228 2.467-2.467 3.695-3.695.022-.022.033-.055.066-.088h-.276v-.022zm-2.346.908c-.641.652-1.283 1.294-1.935 1.936-.786.785-1.848.785-2.633 0-.686-.675-1.372-1.361-2.047-2.047l-.121-.122c1.194-.907 2.146-2.046 3.208-3.053.033-.033.066-.066.11-.088 0 0 .022 0 .033-.022 1.195 1.05 2.157 2.345 3.496 3.263-.044.044-.088.088-.122.133h.011z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Edit(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.3 21.9c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 012.5 20.1V6.7c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h8.55l-1.5 1.5H4.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v13.4c0 .067.033.133.1.2s.133.1.2.1h13.4c.067 0 .133-.033.2-.1s.1-.133.1-.2v-7.075l1.5-1.5V20.1c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H4.3zM15.525 5.325l1.075 1.05-6.6 6.6V14.4h1.4l6.65-6.625 1.05 1.05-6.575 6.575c-.15.15-.325.27-.525.362-.2.092-.417.138-.65.138H9.4a.87.87 0 01-.638-.262A.87.87 0 018.5 15v-1.95c0-.233.042-.45.125-.65.083-.2.2-.375.35-.525l6.55-6.55zm3.575 3.5l-3.575-3.5 2.3-2.3c.35-.35.78-.525 1.288-.525s.937.183 1.287.55l.975.975c.333.35.5.775.5 1.275 0 .5-.175.925-.525 1.275l-2.25 2.25z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Trash(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.3 20.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 015.5 18.7V6h-.25a.726.726 0 01-.75-.75.726.726 0 01.75-.75H9c0-.25.087-.458.262-.625a.892.892 0 01.638-.25h4.2c.25 0 .463.083.638.25A.828.828 0 0115 4.5h3.75a.728.728 0 01.75.75.728.728 0 01-.75.75h-.25v12.7c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H7.3zM7 6v12.7c0 .083.03.154.088.213A.289.289 0 007.3 19h9.4a.292.292 0 00.213-.087A.292.292 0 0017 18.7V6H7zm2.4 10.25a.728.728 0 00.75.75c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538v-7.5a.731.731 0 00-.212-.538A.731.731 0 0010.15 8a.728.728 0 00-.75.75v7.5zm3.7 0c0 .217.07.396.212.538a.731.731 0 00.538.212c.217 0 .396-.07.538-.212a.731.731 0 00.212-.538v-7.5a.731.731 0 00-.212-.538A.731.731 0 0013.85 8a.731.731 0 00-.538.212.731.731 0 00-.212.538v7.5zM7 6v12.7c0 .083.03.154.088.213A.289.289 0 007.3 19H7V6z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Link(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 16.55c-1.25 0-2.32-.445-3.212-1.337C2.896 14.321 2.45 13.25 2.45 12s.446-2.32 1.338-3.213C4.68 7.896 5.75 7.45 7 7.45h3.05a.71.71 0 01.538.225c.142.15.212.325.212.525a.728.728 0 01-.75.75H7c-.833 0-1.55.3-2.15.9-.6.6-.9 1.317-.9 2.15 0 .834.3 1.55.9 2.15.6.6 1.317.9 2.15.9h3.05c.217 0 .396.07.538.212a.731.731 0 01.212.538c0 .2-.07.375-.212.525a.71.71 0 01-.538.225H7zm2-3.8a.726.726 0 01-.75-.75.728.728 0 01.75-.75h6c.217 0 .396.07.538.212a.731.731 0 01.212.538.728.728 0 01-.75.75H9zm4.95 3.8a.706.706 0 01-.537-.225.74.74 0 01-.213-.525.728.728 0 01.75-.75H17c.834 0 1.55-.3 2.15-.9.6-.6.9-1.316.9-2.15 0-.833-.3-1.55-.9-2.15-.6-.6-1.316-.9-2.15-.9h-3.05a.726.726 0 01-.75-.75c0-.2.071-.375.213-.525a.706.706 0 01.537-.225H17c1.25 0 2.32.446 3.212 1.337.892.892 1.338 1.963 1.338 3.213s-.446 2.321-1.338 3.213c-.891.892-1.962 1.337-3.212 1.337h-3.05z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RequestPage(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5425_7988)">
        <path
          d="M13.17 4L18 8.83V20H6V4h7.17zM14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm1 9h-4v1h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1h-1v1h-2v-1H9v-2h4v-1h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1V8h2v1h2v2z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5425_7988">
          <path fill="currentColor" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Faq(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 17.948c.283 0 .521-.1.713-.3.191-.2.287-.442.287-.725a1.01 1.01 0 00-.287-.725.949.949 0 00-.713-.3c-.3 0-.546.1-.737.3-.192.2-.288.442-.288.725s.096.525.288.725c.191.2.437.3.737.3zm-.725-3.6H13.2c0-.484.067-.871.2-1.163.133-.292.45-.687.95-1.187.45-.433.792-.838 1.025-1.213.233-.375.35-.812.35-1.312 0-.867-.308-1.542-.925-2.025-.617-.483-1.35-.725-2.2-.725-.833 0-1.525.225-2.075.675-.55.45-.942.975-1.175 1.575l1.3.5c.117-.317.321-.633.613-.95.291-.317.729-.475 1.312-.475.6 0 1.042.162 1.325.487.283.325.425.68.425 1.063 0 .333-.1.641-.3.925-.2.283-.45.558-.75.825-.65.583-1.062 1.054-1.237 1.413-.175.358-.263.887-.263 1.587zm.725 7.475a9.263 9.263 0 01-3.712-.75 9.58 9.58 0 01-3.013-2.025 9.58 9.58 0 01-2.025-3.013A9.263 9.263 0 013 12.323c0-1.317.25-2.554.75-3.713a9.583 9.583 0 012.025-3.012 9.58 9.58 0 013.013-2.025 9.263 9.263 0 013.712-.75 9.27 9.27 0 013.713.75 9.583 9.583 0 013.012 2.025A9.583 9.583 0 0121.25 8.61a9.27 9.27 0 01.75 3.713c0 1.316-.25 2.554-.75 3.712a9.58 9.58 0 01-2.025 3.013 9.583 9.583 0 01-3.012 2.025 9.27 9.27 0 01-3.713.75zm0-1.5c2.233 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.442 2.325-5.675 0-2.234-.775-4.125-2.325-5.675-1.55-1.55-3.442-2.325-5.675-2.325-2.233 0-4.125.775-5.675 2.325-1.55 1.55-2.325 3.441-2.325 5.675 0 2.233.775 4.125 2.325 5.675 1.55 1.55 3.442 2.325 5.675 2.325z"
        fill="currentColor"
      />
    </svg>
  );
}

export const Devices = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={21}
    viewBox="0 0 13 21"
    fill="none"
    {...props}
  >
    <path
      d="M1.8 21c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 010 19.2V1.8C0 1.3.175.875.525.525.875.175 1.3 0 1.8 0h9.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v17.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H1.8zm-.3-4.725V19.2c0 .067.033.133.1.2s.133.1.2.1h9.4c.067 0 .133-.033.2-.1s.1-.133.1-.2v-2.925h-10zm5 2.5c.25 0 .458-.088.625-.263a.889.889 0 00.25-.637.862.862 0 00-.25-.613A.828.828 0 006.5 17a.828.828 0 00-.625.262.862.862 0 00-.25.613c0 .25.083.462.25.637a.827.827 0 00.625.263zm-5-4h10V4.25h-10v10.525zm0-12.025h10V1.8c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H1.8c-.067 0-.133.033-.2.1s-.1.133-.1.2v.95zm0 13.525V19.5 16.275z"
      fill="currentColor"
    />
  </svg>
);

export const DeviceRinging = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={21}
    viewBox="0 0 19 21"
    fill="none"
    {...props}
  >
    <path
      d="M16.716 6.7l-.95 1c1.711 1.8 1.711 4.6 0 6.5l.95 1c2.378-2.3 2.378-6.1 0-8.5zM14.72 8.8l-.951 1c.475.7.475 1.6 0 2.3l.951 1c1.142-1.2 1.142-3 0-4.3z"
      fill="currentColor"
    />
    <path
      d="M5.05 18.325h2.9a.41.41 0 00.3-.125.44.44 0 00.125-.325.41.41 0 00-.125-.3.41.41 0 00-.3-.125h-2.9a.41.41 0 00-.3.125.41.41 0 00-.125.3.44.44 0 00.125.325.41.41 0 00.3.125zM1.8 21c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 010 19.2V1.8C0 1.3.175.875.525.525.875.175 1.3 0 1.8 0h9.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v17.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H1.8zm-.3-6.225h10V4.25h-10v10.525zm0 1.5V19.2c0 .083.03.154.088.213a.289.289 0 00.212.087h9.4a.292.292 0 00.213-.087.292.292 0 00.087-.213v-2.925h-10zm0-13.525h10V1.8a.292.292 0 00-.087-.213.292.292 0 00-.213-.087H1.8a.289.289 0 00-.212.087.29.29 0 00-.088.213v.95z"
      fill="currentColor"
    />
  </svg>
);

export const MenuKebab = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      d="M14.094 18.826A2.091 2.091 0 0112 20.92a2.091 2.091 0 01-2.093-2.093c0-1.156.936-2.092 2.093-2.092s2.093.936 2.093 2.092zM14.094 6.012A2.091 2.091 0 0112 8.104a2.091 2.091 0 01-2.093-2.092c0-1.157.936-2.093 2.093-2.093s2.093.936 2.093 2.093zM14.094 12.326A2.091 2.091 0 0112 14.42a2.091 2.091 0 01-2.093-2.093c0-1.156.936-2.092 2.093-2.092s2.093.936 2.093 2.092z"
      fill="currentColor"
    />
  </svg>
);

export const Block = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12 21.5a9.263 9.263 0 01-3.712-.75 9.58 9.58 0 01-3.013-2.025 9.58 9.58 0 01-2.025-3.013A9.263 9.263 0 012.5 12c0-1.317.25-2.554.75-3.713a9.583 9.583 0 012.025-3.012A9.58 9.58 0 018.288 3.25 9.263 9.263 0 0112 2.5a9.27 9.27 0 013.713.75 9.583 9.583 0 013.012 2.025 9.583 9.583 0 012.025 3.012A9.27 9.27 0 0121.5 12c0 1.317-.25 2.554-.75 3.712a9.58 9.58 0 01-2.025 3.013 9.583 9.583 0 01-3.012 2.025A9.27 9.27 0 0112 21.5zm0-1.5c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12a7.914 7.914 0 00-1.85-5.1L6.9 18.15a7.856 7.856 0 002.388 1.375A7.906 7.906 0 0012 20zm-6.15-2.9L17.1 5.85A7.915 7.915 0 0012 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 .933.158 1.837.475 2.712A7.857 7.857 0 005.85 17.1z"
      fill="currentColor"
    />
  </svg>
);

export const CheckCircle = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M21.8 10.864c.067.316.117.641.15.975.033.333.05.666.05 1 0 1.316-.25 2.55-.75 3.7a9.655 9.655 0 01-2.038 3.012 9.655 9.655 0 01-3.012 2.038c-1.15.5-2.383.75-3.7.75-1.317 0-2.55-.25-3.7-.75a9.667 9.667 0 01-3.013-2.038 9.664 9.664 0 01-2.037-3.012 9.178 9.178 0 01-.75-3.7c0-1.317.25-2.55.75-3.7a9.676 9.676 0 012.037-3.013A9.676 9.676 0 018.8 4.089c1.15-.5 2.383-.75 3.7-.75.883 0 1.733.116 2.55.35a9.56 9.56 0 012.275.975.724.724 0 01.338.475c.041.2-.013.391-.163.575a.849.849 0 01-.5.313.753.753 0 01-.575-.113 7.064 7.064 0 00-1.85-.8 7.79 7.79 0 00-2.075-.275c-2.217 0-4.104.779-5.662 2.337C5.279 8.734 4.5 10.622 4.5 12.839c0 2.216.78 4.104 2.338 5.663 1.558 1.558 3.445 2.337 5.662 2.337s4.104-.78 5.663-2.337c1.558-1.559 2.337-3.446 2.337-5.663 0-.284-.017-.563-.05-.838a7.69 7.69 0 00-.15-.837.866.866 0 01.075-.463.785.785 0 01.3-.337c.233-.167.463-.192.688-.075.225.117.37.308.437.575zm-11.35 5.6l-2.775-2.775a.653.653 0 01-.187-.513.736.736 0 01.212-.512c.15-.15.33-.225.538-.225.208 0 .379.075.512.225l2.325 2.325 9.375-9.375a.62.62 0 01.513-.213.695.695 0 01.512.238c.15.133.225.304.225.512a.734.734 0 01-.225.538l-9.75 9.775a.934.934 0 01-.65.25.848.848 0 01-.625-.25z"
      fill="currentColor"
    />
  </svg>
);
export const Ballot = (props: ComponentProps<"svg">) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.25 6.5h3.3a.726.726 0 00.75-.75.728.728 0 00-.75-.75h-3.3a.728.728 0 00-.75.75.726.726 0 00.75.75zm0 5.5h3.3a.726.726 0 00.75-.75.728.728 0 00-.75-.75h-3.3a.728.728 0 00-.75.75.726.726 0 00.75.75zM4.8 7.375h1.4a.9.9 0 00.663-.262.9.9 0 00.262-.663v-1.4a.9.9 0 00-.262-.663.9.9 0 00-.663-.262H4.8a.897.897 0 00-.925.925v1.4a.899.899 0 00.925.925zm0 5.5h1.4a.9.9 0 00.663-.262.9.9 0 00.262-.663v-1.4a.9.9 0 00-.262-.663.9.9 0 00-.663-.262H4.8a.897.897 0 00-.925.925v1.4a.899.899 0 00.925.925zM1.8 17c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 010 15.2V1.8C0 1.3.175.875.525.525.875.175 1.3 0 1.8 0h13.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H1.8zm0-1.5h13.4c.067 0 .133-.033.2-.1s.1-.133.1-.2V1.8c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H1.8c-.067 0-.133.033-.2.1s-.1.133-.1.2v13.4c0 .067.033.133.1.2s.133.1.2.1z"
      fill="currentColor"
    />
  </svg>
);
