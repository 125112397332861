import { PendingTransaction } from "@/services/types/get-pending-transactions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";

type HeaderProps = { data: PendingTransaction[] };

export const Header = ({ data }: HeaderProps) => {
  const sum = data.reduce((acc, item) => acc + item.Amount, 0);

  return (
    <div className="flex items-center justify-between rounded-sm bg-white px-[16px] py-[16px] mobile:border desktop:px-6">
      <div className="flex items-center gap-2">
        <div className="flex size-8 items-center justify-center rounded-full bg-status-warning-light">
          <Icon
            name="GeralArrowArrowTransfer"
            className="size-[16px] fill-status-warning-dark"
          />
        </div>

        <div>
          <h2 className="font-medium mobile:text-[12px]">
            Lançamentos pendentes
          </h2>

          <span className="text-[14px] mobile:text-[10px]">
            {data.length} pendentes
          </span>
        </div>
      </div>

      <span className="font-bold text-status-warning-dark">
        <span className="text-[24px] mobile:text-[16px]">
          {formatCurrency(sum)}
        </span>
      </span>
    </div>
  );
};
