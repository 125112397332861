import { useGetWeeklyReport } from "@/services/use-get-weekly-report";
import { formatCurrency, formatNumber } from "@/utils";
import { ApexOptions } from "apexcharts";
import ApexChart from "react-apexcharts";

export function Chart() {
  const { data, isLoading, isError, isFetching } = useGetWeeklyReport();

  const filteredData = data?.chatData || [];

  const isEmpty = data && !data.categories.length;

  const loadingData = isLoading || isFetching;

  const getChartEmptyFeedback = () => {
    if (loadingData && !data.categories.length) return "Carregando...";
    if (isError) return "Houve um problema ao buscar os dados";
    if (isEmpty) return "";
  };

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 305,
      toolbar: {
        show: false,
      },
      offsetX: -8,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20px",
        borderRadius: 3,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      categories: data?.categories,
    },
    yaxis: {
      labels: {
        formatter: (val) => formatNumber(val),
      },
      forceNiceScale: true,
      min: 0,
    },
    fill: {
      colors: ["#0047f6", "#9B1600"],
    },
    tooltip: {
      y: {
        formatter: (val) => `${val > 0 ? formatCurrency(val) : "R$ 0,00"}`,
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: getChartEmptyFeedback(),
      align: "center",
      verticalAlign: "middle",
      offsetX: 15,
      offsetY: -15,
    },
  };

  return (
    <div className="flex flex-col rounded-sm bg-neutral-white px-6 py-4 mobile:!hidden">
      <ApexChart options={options} series={filteredData} type="bar" />

      {isEmpty ? (
        <p className="text-center text-x2s">
          {" "}
          {loadingData ? "" : "Nenhuma movimentação realizada"}
        </p>
      ) : (
        <div className="flex items-center justify-evenly">
          <div className="flex flex-col justify-start">
            <h3 className="text-xs/md font-bold text-neutral-darkest">
              Entradas
            </h3>
            <b className="text-sm/md font-bold text-primary-main">
              {formatCurrency(data?.totalCredit)}
            </b>
          </div>
          <div className="flex flex-col justify-start">
            <h3 className="text-xs/md font-bold text-neutral-darkest">
              Saídas
            </h3>
            <b className="text-sm/md font-bold text-status-error-dark">
              {formatCurrency(data?.totalDebit)}
            </b>
          </div>
        </div>
      )}
    </div>
  );
}
