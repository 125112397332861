import { ScrollArea } from "@/components/scroll-area";
import * as Img from "@/pages/settings/images";
import { TableOptions } from "@/pages/settings/sections/favored/components/table-options";
import {
  formatDocument,
  getInitials,
  ListContactsUseCaseSuccessResponse,
} from "@/utils";
import { Avatar, Button } from "@hyperlocal/vital2";

type EmptyListProps = {
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
};

function EmptyList({ pageNumber, setPageNumber }: EmptyListProps) {
  function handleCleanFilters() {
    setPageNumber(1);
  }

  const hasFilter = pageNumber !== 1;

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2">
      <span className="text-center text-xs/md text-neutral-main">
        {hasFilter ? (
          "Você não possui usuários nesse filtro."
        ) : (
          <>
            <img src={Img.Loupe} alt="Lupa triste" className="mx-auto" />
            <span className="mt-2 block text-center text-xs/md text-neutral-main">
              Aqui está vazio! Por favor, adicione um novo favorecido.
            </span>
          </>
        )}
      </span>

      {hasFilter && (
        <Button.Root variant="link" onClick={handleCleanFilters}>
          Remover filtros
        </Button.Root>
      )}
    </div>
  );
}

const SKELETON_LENGTH = 7;

function TableSkeleton() {
  return (
    <div
      data-testid="table-skeleton"
      className="grid animate-pulse grid-cols-[200px_auto_24px] items-center justify-between gap-4 border-b border-neutral-lighter p-4 mobile:!grid-cols-[auto_24px] mobile:!gap-2 mobile:!p-2"
    >
      <div className="flex items-center gap-2 mobile:!w-2/3 mobile:!py-0.5">
        <div className="block size-11 shrink-0 rounded-circle bg-neutral-lighter" />
        <div>
          <div className="block h-6 w-32 rounded-sm bg-neutral-lighter" />
          <div className="mt-1 hidden h-[18px] w-24 rounded-sm bg-neutral-lighter mobile:!block" />
        </div>
      </div>

      <div className="block h-6 w-32 rounded-sm bg-neutral-lighter mobile:!hidden" />
      <div className="block h-6 w-full rounded-sm bg-neutral-lighter" />
    </div>
  );
}

type TableProps = {
  pageData: ListContactsUseCaseSuccessResponse;
  isLoading: boolean;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
};

export function Table({
  pageData,
  isLoading,
  pageNumber,
  setPageNumber,
}: Readonly<TableProps>) {
  const isEmpty = !pageData || pageData.length === 0;

  if (!isLoading && isEmpty) {
    return <EmptyList pageNumber={pageNumber} setPageNumber={setPageNumber} />;
  }

  return (
    <ScrollArea>
      <div className="space-y-1 pr-6 mobile:!pr-0">
        {isLoading
          ? Array.from({ length: SKELETON_LENGTH }).map((_, index) => (
              <TableSkeleton key={index} />
            ))
          : pageData.map((profile) => (
              <div
                key={profile.id}
                className="grid grid-cols-[200px_auto_24px] items-center justify-between gap-4 border-b border-neutral-lighter p-4 pl-3.5 mobile:!grid-cols-[auto_24px] mobile:!gap-2 mobile:!p-2 mobile:!pl-1.5"
              >
                <div className="flex items-center gap-2.5 overflow-hidden p-0.5">
                  <Avatar.Root size="md" variant="primary" className="shrink-0">
                    <Avatar.Fallback>
                      {getInitials(profile.name)}
                    </Avatar.Fallback>
                  </Avatar.Root>

                  <div>
                    <span
                      className="truncate text-xs/md text-neutral-darkest"
                      title={profile.name}
                    >
                      {profile.name}
                    </span>
                    <span className="hidden text-xs/md tabular-nums text-neutral-black mobile:!mt-1 mobile:!block mobile:!text-x3s/md mobile:!font-medium mobile:!text-neutral-darkest">
                      {formatDocument(profile.document)}
                    </span>
                  </div>
                </div>

                <span className="text-xs/md tabular-nums text-neutral-black mobile:!hidden">
                  {formatDocument(profile.document)}
                </span>

                <TableOptions profile={profile} />
              </div>
            ))}
      </div>
    </ScrollArea>
  );
}
