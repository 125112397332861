import { MutationConfig } from "@/lib";
import { API, contactQueryFactory, useAccountStore } from "@/utils";
import { toast } from "@hyperlocal/vital2";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

type AccountType = "CC" | "SA" | "PA";

export type ContactType =
  | {
      transferType: "p2p";
      contactP2p: {
        branch: string;
        accountNumber: string;
        accountDigit: string;
      };
    }
  | {
      transferType: "pix";
      contactPix: {
        pixKey: string;
      };
    }
  | {
      transferType: "ted";
      contactTed: {
        branch: string;
        branchDigit: string;
        accountNumber: string;
        accountDigit: string;
        bankCode: string;
        bankName: string;
        bankAccountType: AccountType;
      };
    };

export type CreateContactRequest = {
  code: string;
  contactName: string;
  document: string;
} & ContactType;

const createContact = async (payload: CreateContactRequest) => {
  const { data } = await API.contact.post("/Contact", payload);

  return data;
};

export const useCreateContact = (
  config?: MutationConfig<typeof createContact>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationKey: ["createContact"],
    mutationFn: (variables: CreateContactRequest) => createContact(variables),
    onError: (error: AxiosError<{ ErrorMessages: string[] }>) => {
      console.log("error", error.response?.data?.ErrorMessages[0]);

      const errorMessage =
        error.response?.data?.ErrorMessages[0] || "Tente novamente mais tarde.";

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: errorMessage,
      });
    },

    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: contactQueryFactory.default,
      });
      toast({
        variant: "success",
        title: "Favorecido cadastrado",
      });

      config?.onSuccess(data, variables, context);
    },
  });
};

export type TransferType = "pix" | "p2p" | "ted";

export type UpdateContactAccount =
  | {
      Id?: string;
      TransferType: TransferType;
      PixKey: string;
    }
  | {
      Id?: string;
      TransferType: TransferType;
      P2PAccountId?: string;
      Branch: string;
      BranchDigit: string;
      AccountNumber: string;
      AccountDigit: string;
      BankCode: string;
      BankName: string;
    }
  | {
      Id?: string;
      TransferType: TransferType;
      Branch: string;
      BranchDigit: string;
      AccountNumber: string;
      AccountDigit: string;
      BankCode: string;
      BankName: string;
      BankAccountType: AccountType;
    };

export interface UpdateContactRequest {
  accountId: string;
  document: string;
  contactName: string;
  accounts: UpdateContactAccount[];
  isFavoredContact: boolean;
}

export type UseUpdateMutationVariables = Omit<
  UpdateContactRequest,
  "accountId"
>;

const updateContact = async (contact: UpdateContactRequest) => {
  const { data } = await API.contact.put("/Contact", contact);

  return data;
};

export const useUpdateContact = (
  config?: MutationConfig<typeof updateContact>,
) => {
  const { currentAccountId: accountId } = useAccountStore();

  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateContact"],
    mutationFn: (variables: UseUpdateMutationVariables) =>
      updateContact({
        accountId,
        ...variables,
      }),
    onError: () => {
      toast({
        title: "Erro",
        description: "Houve um erro ao atualizar os dados do contato",
        variant: "error",
        position: "top-right",
      });
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: contactQueryFactory.default,
      });
      toast({
        variant: "success",
        title: "Favorecido cadastrado",
      });

      config?.onSuccess(data, { accountId, ...variables }, context);
    },
  });
};
