import {
  ChartData,
  GetChartDataResponse,
  GetChartDataResponseV2,
} from "@/services/types";
import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import apis from ".";
import { useAccountStore, useNewStatementPermission } from "../utils";

const MAX_LENGTH = 7;

type Reducing = {
  categories: string[];
  credit: number[];
  debit: number[];
  totalCredit: number;
  totalDebit: number;
};

type WeekResponse = GetChartDataResponse | GetChartDataResponseV2;

function normalizeItems(response: WeekResponse) {
  if ("Items" in response) {
    return response.Items.map((item) => ({
      date: item.Date,
      credit: item.Credit,
      debit: item.Debit,
    }));
  } else {
    return response.items;
  }
}

const transformWeeklyResponse = ({
  response,
  isNewStatement,
}: {
  isNewStatement: boolean;
  response: WeekResponse;
}): ChartData => {
  const normalizedItems = normalizeItems(response);

  const result = normalizedItems.reduce<Reducing>(
    (prev, cur, index) => {
      if (index <= MAX_LENGTH) {
        prev.totalCredit += cur.credit;
        prev.totalDebit += cur.debit;

        const parsedDate = parse(cur.date, "yyyy-MM-dd", new Date());
        prev.categories.push(format(parsedDate, "dd/MM"));
        prev.credit.push(cur.credit || -0.01);
        prev.debit.push(Math.abs(cur.debit) || -0.01);
      }

      return prev;
    },
    {
      categories: [],
      credit: [],
      debit: [],
      totalCredit: 0,
      totalDebit: 0,
    },
  );

  return {
    categories: isNewStatement
      ? result.categories
      : result.categories.reverse(),
    totalCredit: result.totalCredit,
    totalDebit: result.totalDebit,
    chatData: [
      {
        name: "Entradas",
        data: isNewStatement ? result.credit : result.credit.reverse(),
      },
      {
        name: "Saídas",
        data: isNewStatement ? result.debit : result.debit.reverse(),
      },
    ],
  };
};

export const useGetWeeklyReport = () => {
  const { currentAccountId: accountId } = useAccountStore();
  const useNewStatement = useNewStatementPermission();

  return useQuery<GetChartDataResponse, Error, ChartData>({
    queryKey: ["weeklyReport", accountId],
    queryFn: () => apis.GetChartData({ accountId, useNewStatement }),
    enabled: !!accountId,
    initialData: {
      Balance: {
        Credit: 0,
        Debit: 0,
      },
      Items: [],
      Week: { End: "", Start: "" },
    },
    select: (data) =>
      transformWeeklyResponse({
        response: data,
        isNewStatement: useNewStatement,
      }),
  });
};
