import {
  contactQueryFactory,
  ListContactsUseCaseSuccessResponse,
} from "@/utils";
import { useQuery } from "@tanstack/react-query";

export const FAVORED_LIST_KEY = "get-favored-list";

type UseFavoredListInput = {
  accountId: string;
  document: string;
  favoredContacts: boolean;
};

export function useFavoredList(input?: UseFavoredListInput) {
  const query = useQuery<ListContactsUseCaseSuccessResponse>(
    contactQueryFactory.listFavored({
      ...input,
      favoredContacts: true,
    }),
  );

  return {
    favoredList: query.data,
    isLoading: query.isLoading,
    error: query.error,
    updatedAt: query.dataUpdatedAt || query.errorUpdatedAt,
  };
}
