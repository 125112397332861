import { ReactNode, useState } from "react";
import { queryClient } from "@/lib";
import { API, contactQueryFactory, Hooks } from "@/utils/utility";
import { Button, Dialog, Drawer, toast } from "@hyperlocal/vital2";

type DeleteFavoredModalProps = {
  children?: ReactNode;
  profileDocument: string;
  isOpen?: boolean;
};

export function DeleteFavoredModal({
  children,
  profileDocument,
  isOpen,
}: DeleteFavoredModalProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(false);

  function onOpenChange(open: boolean) {
    setIsModalOpen(open);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  async function handleDeleteFavored() {
    try {
      setIsLoading(true);

      await API.contact.delete("/Contact", {
        params: {
          document: profileDocument,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: contactQueryFactory.default,
      });

      toast({
        variant: "success",
        title: "Favorecido excluído",
      });
      handleCloseModal();
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return isMobile ? (
    <Drawer.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}

      <Drawer.Content onOpenAutoFocus={(event) => event.preventDefault()}>
        <Drawer.Header>
          <Drawer.Title>Excluir favorecido</Drawer.Title>
        </Drawer.Header>
        <div className="mt-6 text-xs/md text-neutral-darker">
          Você tem certeza de que deseja excluir o favorecido? Ao fazer isso,
          todas as transações para este contato estarão sujeitas ao limite
          padrão.
        </div>
        <div className="mt-4 flex gap-2">
          <Drawer.Close asChild>
            <Button.Root variant="secondary" className="w-full border">
              Cancelar
            </Button.Root>
          </Drawer.Close>
          <Button.Root
            className="w-full border-none bg-status-error-default outline-none hover:bg-status-error-dark focus:bg-status-error-dark mobile:!mb-2"
            disabled={isLoading}
            onClick={handleDeleteFavored}
          >
            Excluir
          </Button.Root>
        </div>
      </Drawer.Content>
    </Drawer.Root>
  ) : (
    <Dialog.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}

      <Dialog.Content className="max-w-[372px]">
        <Dialog.Header>
          <Dialog.Title>Excluir favorecido</Dialog.Title>
        </Dialog.Header>
        <div className="text-xs/md text-neutral-darker">
          Você tem certeza de que deseja excluir o favorecido? Ao fazer isso,
          todas as transações para este contato estarão sujeitas ao limite
          padrão.
        </div>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button.Root variant="secondary" className="w-full border">
              Cancelar
            </Button.Root>
          </Dialog.Close>
          <Button.Root
            className="w-full border-none bg-status-error-default outline-none hover:bg-status-error-dark focus:bg-status-error-dark mobile:!mb-2"
            disabled={isLoading}
            onClick={handleDeleteFavored}
          >
            Excluir
          </Button.Root>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
}
