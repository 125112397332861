import { useEffect, useState } from "react";
import { appVersionQueryFactory, DASHBOARD_CDN } from "@/utils";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export function useCheckAppVersion() {
  const [route, setRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };

    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return useQuery(
    appVersionQueryFactory.check({
      url: DASHBOARD_CDN,
      route,
      storageKey: "DASHBOARD_VERSION",
    }),
  );
}
