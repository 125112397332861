import { useState } from "react";
import {
  CreateContactRequest,
  useCreateContact,
  useUpdateContact,
} from "@/api/account-management/manage-contacts";
import { useFavoredList } from "@/pages/settings/react-query/favored-list";
import { SelectBankComboBox } from "@/pages/settings/sections/favored/components/select-bank-combo-box";
import { DOCK_BANK_CODE } from "@/utils/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  RadioGroup,
  Select,
  Sheet,
  TextField,
  toast,
  Token,
} from "@hyperlocal/vital2";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import {
  ContactExistsStatus,
  createContactDataLookup,
  getContactExistsStatus,
  getUpdateContactData,
  LookupState,
  newFavoredSchema,
  NewFavoredSchemaInput,
  NewFavoredSheetProps,
  radioGroupOptions,
  selectOptions,
  Step,
  stepTitle,
} from "./new-favored-sheet-helper";

export function NewFavoredSheet({ isOpen }: NewFavoredSheetProps) {
  const [isSheetOpen, setIsSheetOpen] = useState(isOpen);
  const [totpCode, setTotpCode] = useState("");
  const [step, setStep] = useState<Step>("FORM");
  const methods = useForm<NewFavoredSchemaInput>({
    resolver: zodResolver(newFavoredSchema),
    mode: "onTouched",
    defaultValues: {
      type: "Conta bancária",
      bankType: "Corrente",
      pixKey: "",
    },
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = methods;

  const { favoredList } = useFavoredList();

  const registerWithMask = useHookFormMask(register);
  const values = watch();

  function onOpenChange(open: boolean) {
    if (open) {
      reset();
      setStep("FORM");
    }

    setIsSheetOpen(open);
  }

  function handleCloseSheet() {
    setIsSheetOpen(false);
  }

  const createContact = useCreateContact({
    onSuccess: () => handleCloseSheet(),
  });

  const updateContact = useUpdateContact({
    onSuccess: () => handleCloseSheet(),
  });

  const handleCreateContact = async (formData: NewFavoredSchemaInput) => {
    const isPixType = formData.type === "Chave Pix";

    const state: LookupState = {
      isPix: isPixType,
      isP2P: !isPixType && formData.bank.bankCode === DOCK_BANK_CODE,
      isTed: !isPixType && formData.bank.bankCode !== DOCK_BANK_CODE,
    };

    const { getCreateContactData } = createContactDataLookup.find(
      ({ condition }) => condition(state),
    );

    const transferData = getCreateContactData(formData);

    if (!transferData) {
      throw new Error("Transfer data not found");
    }

    const createContactPayload = {
      code: totpCode,
      contactName: formData.name,
      document: formData.document,
      transferType: transferData.transferType,
      ...transferData.payload,
    };

    await createContact.mutateAsync(
      createContactPayload as CreateContactRequest,
    );
  };

  const handleUpdateContact = async (
    formData: NewFavoredSchemaInput,
    status: ContactExistsStatus,
  ) => {
    const payload = getUpdateContactData({
      contactList: favoredList,
      formData,
      status,
    });

    await updateContact.mutateAsync(payload);
  };

  async function handleVerification() {
    const formData = newFavoredSchema.parse(values);

    await handleCreateContact(formData);
  }

  async function goToNextStep() {
    try {
      const formData = newFavoredSchema.parse(values);

      const status = getContactExistsStatus({
        form: formData,
        contactList: favoredList,
      });

      if (status === "NEW_CONTACT") return setStep("TOKEN");

      await handleUpdateContact(formData, status);
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });
      }
    }
  }

  const hasErrors = !newFavoredSchema.safeParse(values).success;
  const isTotpCodeEmpty = totpCode.length !== 6;

  return (
    <Sheet.Root onOpenChange={onOpenChange} open={isSheetOpen}>
      <Sheet.Trigger asChild>
        <Button.Root>Novo favorecido</Button.Root>
      </Sheet.Trigger>

      <Sheet.Content
        onOpenAutoFocus={(event) => event.preventDefault()}
        className="px-4 font-base"
      >
        <Sheet.Header>
          <Sheet.Title>{stepTitle[step]}</Sheet.Title>
        </Sheet.Header>

        {step === "FORM" && (
          <form
            className="mt-4 flex flex-1 flex-col justify-between"
            onSubmit={handleSubmit(goToNextStep)}
          >
            <FormProvider {...methods}>
              <div className="space-y-6">
                <div>
                  <TextField.Label
                    className="mobile:!text-x2s/default"
                    htmlFor="document"
                  >
                    CPF/CNPJ
                  </TextField.Label>
                  <TextField.Input
                    id="document"
                    data-status={errors.document && "error"}
                    className="tabular-nums"
                    placeholder="___.___.___-__"
                    {...registerWithMask("document", [
                      "999.999.999-99",
                      "99.999.999/9999-99",
                    ])}
                  />
                  {errors.document && (
                    <TextField.Helper>
                      {errors.document.message}
                    </TextField.Helper>
                  )}
                </div>

                <div>
                  <TextField.Label
                    className="mobile:!text-x2s/default"
                    htmlFor="name"
                  >
                    Nome do contato
                  </TextField.Label>
                  <TextField.Input
                    id="name"
                    autoComplete="name"
                    placeholder="Digite o nome"
                    data-status={errors.name && "error"}
                    {...register("name")}
                  />
                  {errors.name && (
                    <TextField.Helper>{errors.name.message}</TextField.Helper>
                  )}
                </div>

                <div>
                  <h3 className="mb-4 text-sm/md font-bold text-neutral-darkest">
                    Dados bancários
                  </h3>

                  <div className="space-y-6">
                    <div>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <Select.Root
                            value={field.value}
                            onValueChange={field.onChange}
                            onOpenChange={(value) => !value && field.onBlur()}
                          >
                            <Select.Label htmlFor="access-level">
                              Tipo
                            </Select.Label>
                            <Select.Trigger
                              id="access-level"
                              data-testid="access-level-trigger"
                              status={errors.type ? "error" : undefined}
                              className="peer w-full gap-2 data-[status=error]:!border-status-error-default first:[&_span]:truncate"
                            >
                              <Select.Value />
                            </Select.Trigger>
                            <Select.Content className="w-[var(--radix-select-trigger-width)] font-base">
                              <Select.Group>
                                {selectOptions.map((option) => (
                                  <Select.Item key={option} value={option}>
                                    {option}
                                  </Select.Item>
                                ))}
                              </Select.Group>
                            </Select.Content>
                          </Select.Root>
                        )}
                      />
                      {errors.type && (
                        <Select.Helper>{errors.type.message}</Select.Helper>
                      )}
                    </div>

                    {values.type === "Chave Pix" && (
                      <div>
                        <TextField.Label
                          className="mobile:!text-x2s/default"
                          htmlFor="pixKey"
                        >
                          Chave Pix
                        </TextField.Label>
                        <TextField.Input
                          id="pixKey"
                          placeholder="Digite a chave Pix"
                          data-status={errors.pixKey && "error"}
                          {...register("pixKey")}
                        />
                        {errors.pixKey && (
                          <TextField.Helper>
                            {errors.pixKey.message}
                          </TextField.Helper>
                        )}
                      </div>
                    )}

                    {values.type === "Conta bancária" && (
                      <>
                        <SelectBankComboBox />

                        <Controller
                          name="bankType"
                          control={control}
                          render={({ field }) => (
                            <RadioGroup.Root
                              orientation="horizontal"
                              className="justify-between"
                              value={field.value}
                              onValueChange={field.onChange}
                            >
                              {radioGroupOptions.map((item) => (
                                <RadioGroup.Items key={item} className="pl-0">
                                  <RadioGroup.Item value={item} id={item} />
                                  <RadioGroup.Label htmlFor={item}>
                                    {item}
                                  </RadioGroup.Label>
                                </RadioGroup.Items>
                              ))}
                            </RadioGroup.Root>
                          )}
                        />

                        <div className="grid grid-cols-[1fr_2.5fr] gap-4">
                          <div>
                            <TextField.Label
                              className="mobile:!text-x2s/default"
                              htmlFor="agency"
                            >
                              Agência
                            </TextField.Label>
                            <TextField.Input
                              id="agency"
                              placeholder="Digite"
                              data-status={errors.agency && "error"}
                              {...register("agency")}
                            />
                            {errors.agency && (
                              <TextField.Helper>
                                {errors.agency.message}
                              </TextField.Helper>
                            )}
                          </div>
                          <div>
                            <TextField.Label
                              className="mobile:!text-x2s/default"
                              htmlFor="accountNumber"
                            >
                              Conta
                            </TextField.Label>
                            <TextField.Input
                              id="accountNumber"
                              placeholder="Digite"
                              data-status={errors.accountNumber && "error"}
                              {...register("accountNumber")}
                            />
                            {errors.accountNumber && (
                              <TextField.Helper>
                                {errors.accountNumber.message}
                              </TextField.Helper>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <Sheet.Footer className="flex flex-row pb-inline-x2s pt-inset-xl">
                <Button.Root
                  type="button"
                  variant="link"
                  className="w-full"
                  onClick={handleCloseSheet}
                >
                  Cancelar
                </Button.Root>

                <Button.Root
                  variant="primary"
                  className="w-full whitespace-nowrap"
                  disabled={hasErrors}
                  isLoading={updateContact.isPending}
                >
                  Cadastrar
                </Button.Root>
              </Sheet.Footer>
            </FormProvider>
          </form>
        )}

        {step === "TOKEN" && (
          <div className="flex flex-1 flex-col justify-between">
            <div>
              <h3 className="mb-4 text-sm/md font-bold text-neutral-darkest">
                Informe o código de autenticação
              </h3>

              <ul className="mx-5 list-disc text-neutral-darkest mobile:!text-x2s/normal">
                <li>
                  Abra seu aplicativo Google Authenticator e insira o código de
                  seis dígitos exibido no aplicativo.
                </li>
              </ul>

              <div className="mt-8 w-fit">
                <Token.Label className="mobile:!text-x2s/default">
                  Digite o código
                </Token.Label>
                <Token.Root onValueChange={setTotpCode}>
                  <Token.Input />
                  <Token.Input />
                  <Token.Input />
                  <Token.Input />
                  <Token.Input />
                  <Token.Input />
                </Token.Root>
              </div>
            </div>

            <Button.Root
              variant="primary"
              className="mx-auto mt-8 w-full justify-center"
              onClick={handleVerification}
              disabled={isTotpCodeEmpty}
            >
              Verificar
            </Button.Root>
          </div>
        )}
      </Sheet.Content>
    </Sheet.Root>
  );
}
