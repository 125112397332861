import React, { useEffect, useState } from "react";
import { useTeamAccessListFilter } from "@/pages/settings/contexts/team-access-filter";
import { useTeamAccessList } from "@/pages/settings/react-query/team-access-list";
import { Table } from "@/pages/settings/sections/team-access/components/table";
import { TablePagination } from "@/pages/settings/sections/team-access/components/table-pagination";
import {
  getDashboardRoutesData,
  Hooks,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button } from "@hyperlocal/vital2";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

const ROWS_PER_PAGE = 10;
const routes = getDashboardRoutesData();

export function chunkArray<T>(array: T[] = [], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export function getLastUpdate(timeStamp: number) {
  if (timeStamp === 0) return `Última atualização há menos de 5 segundos.`;

  const formattedDistance = formatDistanceToNow(timeStamp, {
    addSuffix: true,
    includeSeconds: true,
    locale: ptBR,
  });

  return `Última atualização ${formattedDistance}.`;
}

export function TeamAccess() {
  const { filterState, dispatchFilter } = useTeamAccessListFilter();
  const {
    data: dataActive = [],
    isLoading: isLoadingActive,
    dataUpdatedAt: dataUpdatedAtActive,
    errorUpdatedAt: errorUpdatedAtActive,
  } = useTeamAccessList({ deleted: false });
  const {
    data: dataDeleted = [],
    isLoading: isLoadingDeleted,
    dataUpdatedAt: dataUpdatedAtDeleted,
    errorUpdatedAt: errorUpdatedAtDeleted,
  } = useTeamAccessList({ deleted: true });
  const [lastUpdate, setLastUpdate] = useState("");
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();

  function handleSelectedExcluded() {
    dispatchFilter({
      type: "SET_SELECTED_EXCLUDED",
      payload: { selectedExcluded: !filterState.selectedExcluded },
    });
  }

  const updatedAtActive = dataUpdatedAtActive || errorUpdatedAtActive;
  const updatedAtDeleted = dataUpdatedAtDeleted || errorUpdatedAtDeleted;

  const data = filterState.selectedExcluded ? dataDeleted : dataActive;
  const isLoading = filterState.selectedExcluded
    ? isLoadingDeleted
    : isLoadingActive;
  const dataUpdatedAt = filterState.selectedExcluded
    ? updatedAtDeleted
    : updatedAtActive;

  const pages = chunkArray(data, ROWS_PER_PAGE);
  const pagesCount = pages.length;
  const currentPage = isMobile ? data : pages[filterState.pageNumber - 1];

  useEffect(() => {
    // Atualiza a cada 1 segundo
    const intervalId = setInterval(() => {
      setLastUpdate(getLastUpdate(dataUpdatedAt));
    }, 1000);

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, [dataUpdatedAt]);

  return (
    <div className="flex h-full flex-col">
      <Button.Root
        className="m-0 !h-fit w-fit !p-[2px]"
        variant={filterState.selectedExcluded ? "primary" : "secondary"}
        onClick={handleSelectedExcluded}
        disabled={isLoadingDeleted || dataDeleted.length === 0}
      >
        Visualizar acessos excluídos
      </Button.Root>

      <div className="-mr-6 mt-2 flex flex-1 flex-col justify-between overflow-hidden mobile:!mr-0">
        <Table pageData={currentPage} isLoading={isLoading} />

        <div className="flex items-center justify-between pr-6 mobile:!hidden mobile:!pr-0">
          {isLoading ? (
            <div className="block h-[21px] w-[280px] animate-pulse rounded-sm bg-neutral-lighter" />
          ) : (
            <span className="text-x2s/md text-neutral-darkest">
              {lastUpdate || getLastUpdate(dataUpdatedAt)}
            </span>
          )}

          <TablePagination pagesCount={pagesCount} isLoading={isLoading} />
        </div>
      </div>

      <Button.Root
        onClick={() => navigate(routes.settingsNewAccess)}
        className="mt-4 hidden mobile:!block"
      >
        Novo membro
      </Button.Root>
    </div>
  );
}
