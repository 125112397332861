import { useCallback, useEffect, useState } from "react";
import EmptyLogo from "@/assets/lupinha.png";
import { ScrollArea } from "@/components/scroll-area";
import { PendingTransaction } from "@/services/types/get-pending-transactions";
import { formatLabel } from "@/utils/pending-transactions/format-label";
import { groupByDate } from "@/utils/pending-transactions/group-by-date";
import {
  formatCurrency,
  getDashboardRoutesData,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { Button, Checkbox } from "@hyperlocal/vital2";
import { format, isToday } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const EmptyList = () => (
  <div className="flex h-[637px] flex-1 flex-col items-center justify-center rounded-sm bg-white">
    <img src={EmptyLogo} alt="" />

    <p className="text-center text-[#A6A6A6]">
      Aqui está vazio! Nenhum lançamento pendente.
    </p>
  </div>
);

type ListProps = {
  data: PendingTransaction[];
};

export const List = ({ data }: ListProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const navigate = useNavigate();
  const allTransactions = searchParams.getAll("transaction");

  const handleSelectTransaction = (transactionId: string) => {
    setSearchParams((params) => {
      params.append("transaction", transactionId);
      return params;
    });
  };

  const handleUnselectTransaction = (transactionId: string) => {
    setSearchParams((params) => {
      const transactions = params
        .getAll("transaction")
        .filter((id) => id !== transactionId);

      params.delete("transaction");
      transactions.forEach((id) => params.append("transaction", id));

      return params;
    });
  };

  const verifyIsActive = useCallback(
    (transactionId: string) => {
      return allTransactions.includes(transactionId);
    },
    [allTransactions],
  );

  const handleSelectAll = () => {
    setSearchParams((params) => {
      data.forEach(({ TransactionId }) => {
        params.append("transaction", TransactionId);
      });

      return params;
    });
    setIsAllSelected(true);
  };

  const handleUnselectAll = () => {
    setSearchParams((params) => {
      data.forEach(({ TransactionId }) => {
        const transactions = params
          .getAll("transaction")
          .filter((id) => id !== TransactionId);

        params.delete("transaction");
        transactions.forEach((id) => params.append("transaction", id));
      });
      return params;
    });

    setIsAllSelected(false);
  };

  const handleToggleSelectAll = (checked: boolean) => {
    if (checked) {
      handleSelectAll();
    } else {
      handleUnselectAll();
    }
  };

  const allSelected = data.every(({ TransactionId }) =>
    verifyIsActive(TransactionId),
  );

  useEffect(() => {
    setIsAllSelected(allSelected);
  }, [allSelected]);

  return (
    <>
      <ScrollArea className="h-full rounded-sm bg-white mobile:max-h-[68vh] desktop:max-h-[637px]">
        <div className="flex-1 space-y-[24px] desktop:p-[24px]">
          <div className="flex items-center">
            <Checkbox.Slot
              id="select-all"
              onCheckedChange={handleToggleSelectAll}
              checked={isAllSelected}
              className="flex-shrink-0 wildcard-checkbox-pending-transaction"
            >
              Selecionar todos
            </Checkbox.Slot>
            <Checkbox.Label htmlFor="select-all">
              Selecionar todos
            </Checkbox.Label>
          </div>

          <div className="space-y-[16px]">
            {groupByDate(data).map(({ date, items }) => (
              <div className="space-y-[16px]" key={date}>
                <div className="flex items-center justify-between">
                  <h5 className="text-[20px] font-bold text-neutral-darkest">
                    {isToday(new Date(date)) && "Hoje, "}

                    {format(new Date(date), "dd 'de' MMMM", {
                      locale: ptBR,
                    })}
                  </h5>

                  <span className="text-neutral-dark">
                    {format(new Date(date), "EEEE", {
                      locale: ptBR,
                    })}
                  </span>
                </div>

                {items.map(
                  ({
                    TransactionId,
                    BeneficiaryName,
                    DateTransaction,
                    Amount,
                    TransactionStatus,
                    TransactionType,
                  }) => {
                    const isActive = verifyIsActive(TransactionId);

                    return (
                      <div
                        className={twMerge(
                          "flex items-center justify-between border-b px-6 py-3",
                          isActive && "rounded-sm border border-primary-main",
                        )}
                        key={TransactionId}
                      >
                        <div className="flex items-center gap-4">
                          <Checkbox.Slot
                            id={`select-${TransactionId}`}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                return handleSelectTransaction(TransactionId);
                              }

                              handleUnselectTransaction(TransactionId);
                            }}
                            checked={isActive}
                            className="flex-shrink-0"
                          />

                          <div className="flex items-center gap-[8px]">
                            <div className="flex size-[32px] flex-shrink-0 items-center justify-center rounded-full bg-status-warning-light">
                              <Icon
                                name="GeralMoneyPayReceived"
                                className="size-[24px] fill-status-warning-dark"
                              />
                            </div>

                            <div className="space-y-1">
                              <h2 className="text-[14px] font-medium">
                                {formatLabel({
                                  TransactionStatus,
                                  TransactionType,
                                })}
                              </h2>

                              <p className="text-neutral-dark">
                                {BeneficiaryName}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-shrink-0 flex-col items-end">
                          <h2 className="text-[14px] text-neutral-dark">
                            {format(new Date(DateTransaction), "HH:mm")}
                          </h2>

                          <span className="text-[14px] text-status-warning-dark">
                            {formatCurrency(Amount)}
                          </span>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            ))}
          </div>
        </div>
      </ScrollArea>

      <Button.Root
        onClick={() =>
          navigate(
            getDashboardRoutesData().pendingTransactionsDetails +
              "?" +
              searchParams.toString(),
          )
        }
        disabled={allTransactions.length === 0}
        className="desktop:!hidden"
      >
        Visualizar
      </Button.Root>
    </>
  );
};
