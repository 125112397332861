import {
  incomeTaxFactory,
  ListTaxReportsUseCaseSuccessResponse,
  useAccountStore,
} from "@/utils";
import { useQuery } from "@tanstack/react-query";

const EMPTY_REPORT_MESSAGE = "Não foram encontrados relatórios para esta conta";

export const useListTaxReports = () => {
  const { currentAccountId } = useAccountStore();

  return useQuery<ListTaxReportsUseCaseSuccessResponse>({
    ...incomeTaxFactory.taxIncomeports({ accountId: currentAccountId }),
    throwOnError: (error) => {
      return !error.message.includes(EMPTY_REPORT_MESSAGE);
    },
  });
};
