import { ReactNode, useState } from "react";
import { queryClient } from "@/lib";
import { TeamListItem } from "@/pages/settings/react-query/team-access-list";
import {
  newUserSchema,
  NewUserSchemaInput,
  NewUserSchemaOutput,
  Role,
  SELECT_OPTIONS,
} from "@/pages/settings/sections/team-access/new-access/components/new-user-form";
import { API, queries, useAccountStore } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Select, Sheet, TextField, toast } from "@hyperlocal/vital2";
import { Controller, useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";

const { teamAccessKeys } = queries;

type ChangeUserSheetProps = {
  children?: ReactNode;
  profile: TeamListItem;
  isOpen?: boolean;
};

const isLoading = false;

type Id = `${string}-${string}-${string}-${string}-${string}`;

export const mappedRole: Record<Role, Id> = {
  Moderador: "d34f9539-283e-48b8-8c5c-095a5fa4d250",
  Administrador: "a97f39d8-6c4e-466a-860c-93aa6bb15052",
  Financeiro: "50f930fb-91f5-4b13-8c39-256a6fb006d0",
  Analista: "d4f9411d-f07b-4566-8be9-0bc1053b81c0",
};

export function ChangeUserSheet({
  children,
  profile,
  isOpen,
}: ChangeUserSheetProps) {
  const [isSheetOpen, setIsSheetOpen] = useState(isOpen);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<NewUserSchemaInput>({
    resolver: zodResolver(newUserSchema),
    mode: "onTouched",
    values: {
      cpf: profile?.document,
      name: profile?.name,
      email: profile?.email,
      phone: profile?.phone,
      role: profile?.role,
    },
  });

  const { currentAccountId } = useAccountStore();

  const registerWithMask = useHookFormMask(register);

  function onOpenChange(open: boolean) {
    if (open) {
      reset();
    }

    setIsSheetOpen(open);
  }

  function handleCloseSheet() {
    setIsSheetOpen(false);
  }

  async function handleFormSubmit(data) {
    try {
      setIsSendingForm(true);

      const formData = data as NewUserSchemaOutput;

      await API.accessControl.put("/api/UserManager", {
        id: profile.id,
        name: formData.name,
        contact: formData.phone,
        accessId: mappedRole[formData.role],
      });

      queryClient.invalidateQueries({
        queryKey: teamAccessKeys.list({
          accountId: currentAccountId,
          deleted: false,
        }),
      });
      toast({
        variant: "success",
        title: "Dados alterados",
      });
      handleCloseSheet();
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsSendingForm(false);
    }
  }

  const values = watch();
  const hasErrors = !newUserSchema.safeParse(values).success;

  return (
    <Sheet.Root onOpenChange={onOpenChange} open={isSheetOpen}>
      {children && <Sheet.Trigger asChild>{children}</Sheet.Trigger>}

      <Sheet.Content
        onOpenAutoFocus={(event) => event.preventDefault()}
        className="px-4 font-base"
      >
        <Sheet.Header>
          <Sheet.Title>Editar</Sheet.Title>
        </Sheet.Header>

        <form
          className="mt-4 flex flex-1 flex-col justify-between"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className="space-y-[24px]">
            <div>
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="name"
              >
                Nome do usuário
              </TextField.Label>
              {isLoading ? (
                <div className="block h-[45px] animate-pulse rounded-xs bg-neutral-light opacity-15" />
              ) : (
                <TextField.Input
                  id="name"
                  autoComplete="name"
                  placeholder="Digite o nome"
                  data-status={errors.name && "error"}
                  {...register("name")}
                />
              )}
              {errors.name && (
                <TextField.Helper>{errors.name.message}</TextField.Helper>
              )}
            </div>

            <div>
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="cpf"
              >
                CPF
              </TextField.Label>
              {isLoading ? (
                <div className="block h-[45px] animate-pulse rounded-xs bg-neutral-light opacity-15" />
              ) : (
                <TextField.Input
                  id="cpf"
                  data-status={errors.cpf && "error"}
                  className="tabular-nums"
                  placeholder="___.___.___-__"
                  disabled
                  {...registerWithMask("cpf", ["999.999.999-99"])}
                />
              )}
              {errors.cpf && (
                <TextField.Helper>{errors.cpf.message}</TextField.Helper>
              )}
            </div>

            <div>
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="phone"
              >
                Contato
              </TextField.Label>
              {isLoading ? (
                <div className="block h-[45px] animate-pulse rounded-xs bg-neutral-light opacity-15" />
              ) : (
                <TextField.Input
                  id="phone"
                  autoComplete="tel"
                  data-status={errors.phone && "error"}
                  className="tabular-nums"
                  placeholder="(__) _ ____-____"
                  {...registerWithMask("phone", ["(99) 9 9999-9999"])}
                />
              )}
              {errors.phone && (
                <TextField.Helper>{errors.phone.message}</TextField.Helper>
              )}
            </div>

            <div>
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="email"
              >
                E-mail de acesso
              </TextField.Label>
              {isLoading ? (
                <div className="block h-[45px] animate-pulse rounded-xs bg-neutral-light opacity-15" />
              ) : (
                <TextField.Input
                  id="email"
                  autoComplete="email"
                  placeholder="Digite o e-mail"
                  data-status={errors.email && "error"}
                  disabled
                  {...register("email")}
                />
              )}
              {errors.email && (
                <TextField.Helper>{errors.email.message}</TextField.Helper>
              )}
            </div>

            <div>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    value={field.value}
                    onValueChange={field.onChange}
                    onOpenChange={(value) => !value && field.onBlur()}
                  >
                    <Select.Label htmlFor="access-level">
                      Nível de acesso
                    </Select.Label>
                    <Select.Trigger
                      id="access-level"
                      disabled={isLoading}
                      data-testid="access-level-trigger"
                      status={errors.role ? "error" : undefined}
                      className="peer w-full gap-2 data-[status=error]:!border-status-error-default first:[&_span]:truncate"
                    >
                      <Select.Value placeholder="Buscando nível..." />
                    </Select.Trigger>
                    <Select.Content className="w-[var(--radix-select-trigger-width)] font-base">
                      <Select.Group>
                        {SELECT_OPTIONS.map((option) => (
                          <Select.Item
                            key={option.value}
                            value={option.value}
                            description={
                              <span
                                key={option.value}
                                className="block text-x2s/md text-neutral-dark"
                              >
                                {option.description}
                              </span>
                            }
                          >
                            {option.value}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                )}
              />
              {errors.role && (
                <Select.Helper>{errors.role.message}</Select.Helper>
              )}
            </div>
          </div>

          <div className="flex gap-1">
            <Button.Root
              type="button"
              variant="link"
              className="w-full"
              onClick={handleCloseSheet}
            >
              Cancelar
            </Button.Root>

            <Button.Root
              variant="primary"
              className="w-full whitespace-nowrap"
              disabled={hasErrors}
              isLoading={isSendingForm}
            >
              Salvar alteração
            </Button.Root>
          </div>
        </form>
      </Sheet.Content>
    </Sheet.Root>
  );
}
