import { clientInfoFactory } from "@/utils";
import { useQuery } from "@tanstack/react-query";

export const useGetIp = () => {
  return useQuery<{
    ipAddress: string;
    latitude: string;
    longitude: string;
  }>({ ...clientInfoFactory.getIp(), enabled: false });
};
