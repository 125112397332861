import {
  requestIncomeTaxUseCase,
  RequestIncomeTaxUseCaseRequest,
} from "@/utils";
import { toast } from "@hyperlocal/vital2";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, UseMutationResult } from "@tanstack/react-query";

const requestTaxIncome = async (data: RequestIncomeTaxUseCaseRequest) => {
  const response = await requestIncomeTaxUseCase.execute(data);

  if (response.isFailure()) {
    throw new Error(
      response.value?.errorMessages?.join(", ") ||
        "Não foi possível solicitar o relatório de IR.",
    );
  }
};

export const useRequestTaxIncome = () => {
  return useMutation({
    mutationFn: requestTaxIncome,
    onError: (error) => {
      toast({ variant: "error", title: "Erro", description: error.message });
    },
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Solicitação enviada com sucesso!",
      });
    },
  });
};
