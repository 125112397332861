import { useReducer } from "react";
import {
  useRegisterDevice,
  useVerifyDeviceQuery,
} from "@/api/device-fingerprint";
import { DeviceAliasSheet, MFASheet } from "@/components/trusted-devices";
import { useGetIp } from "@/services/use-get-ip";
import {
  hasPermission,
  useAccountStore,
  useDeviceFingerprint,
} from "@/utils/utility";
import { Button } from "@hyperlocal/vital2";

type Step = "closed" | "set_alias" | "set_mfa";

type State = {
  step: Step;
  error?: string;
  deviceAlias?: string;
};

type Action =
  | { type: "OPEN_ALIAS" }
  | { type: "SUBMIT_ALIAS"; payload: string }
  | { type: "CLOSE" }
  | { type: "SET_ERROR"; payload: string };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "OPEN_ALIAS":
      return { ...state, step: "set_alias" };
    case "SUBMIT_ALIAS":
      return {
        ...state,
        deviceAlias: action.payload,
        step: "set_mfa",
      };
    case "CLOSE":
      return {
        ...state,
        step: "closed",
        error: undefined,
        deviceAlias: undefined,
      };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export function NewTrustedDevice() {
  const deviceFingerPrint = useDeviceFingerprint();
  const deviceId = deviceFingerPrint?.deviceId;
  const { currentAccountId } = useAccountStore();
  const canRegisterDevice = hasPermission(["Administrador", "Proprietário"]);
  const registerDeviceMutation = useRegisterDevice();
  const { isLoading, isError } = useVerifyDeviceQuery({
    enableQuery: canRegisterDevice && !!deviceId,
    deviceId,
  });
  const { refetch: fetchIp } = useGetIp();

  const [state, dispatch] = useReducer(reducer, {
    step: "closed",
  });
  const isNewDeviceButtonDisabled = !isError || isLoading;
  const handleClose = () => dispatch({ type: "CLOSE" });

  const handleSetAlias = (alias: string) => {
    dispatch({ type: "SUBMIT_ALIAS", payload: alias });
  };

  const handleMfaSubmit = async (token: string) => {
    fetchIp().then(({ data: getIpData }) => {
      registerDeviceMutation.mutate({
        deviceId,
        latitude: getIpData?.latitude,
        longitude: getIpData?.longitude,
        accountId: currentAccountId,
        ipAddress: getIpData?.ipAddress,
        deviceAlias: state.deviceAlias, // Usando o alias do state
        deviceModel: deviceFingerPrint?.deviceModel,
        operatingSystem: deviceFingerPrint?.operatingSystem,
        token,
      });
    });
    try {
      dispatch({ type: "CLOSE" });
    } catch (error) {
      console.log("handleMfaSubmit ~ error", error);
      dispatch({ type: "SET_ERROR", payload: "Erro ao processar MFA" });
    }
  };

  return (
    <>
      <Button.Root
        disabled={isNewDeviceButtonDisabled}
        isLoading={registerDeviceMutation.isPending}
        onClick={() => dispatch({ type: "OPEN_ALIAS" })}
      >
        Novo Dispositivo
      </Button.Root>

      <DeviceAliasSheet
        errorMessage={state.error}
        onSubmitDeviceName={handleSetAlias}
        isOpen={state.step === "set_alias"}
        onClose={handleClose}
      />
      <MFASheet
        isLoading={false} // Ajuste conforme sua necessidade
        onSubmitToken={handleMfaSubmit}
        isOpen={state.step === "set_mfa"}
        onClose={handleClose}
      />
    </>
  );
}
