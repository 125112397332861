import { useCheckAppVersion } from "@/api/use-check-app-version";
import { DashboardPay } from "@/components/dashboard-pay";
import { DashboardPayAnticipationPage } from "@/components/dashboard-pay/dashboard-pay-anticipation-page";
import { DrawerContextProvider } from "@/contexts/drawer";
import { LoginProvider } from "@/contexts/login";
import { ResetPasswordProvider } from "@/contexts/reset-password";
import { Contracts } from "@/pages/contracts";
import { Dashboard } from "@/pages/dashboard";
import { Login } from "@/pages/login";
import { PendingTransactions } from "@/pages/pending-transactions";
import { PendingTransactionsDetails } from "@/pages/pending-transactions/pending-transactions-details";
import { PrivacyPolicies } from "@/pages/privacy-policies";
import { ResetPassword } from "@/pages/reset-password";
import { Settings } from "@/pages/settings";
import { NewAccess } from "@/pages/settings/sections/team-access/new-access";
import {
  EmptyState,
  fetchAuthSession,
  getDashboardRoutesData,
  getVertical,
  getVerticalAccountId,
  isAuthenticated,
  ProtectedRoute,
  setHeader,
  useAccountStore,
} from "@/utils";
import {
  Navigate,
  Outlet,
  redirect,
  RouteObject,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";

const dashboardRoutes = getDashboardRoutesData();

function getAccessToken() {
  const keys = Object.keys(localStorage);
  return keys.find(
    (key) =>
      key.startsWith("CognitoIdentityServiceProvider") &&
      key.endsWith("accessToken"),
  );
}

function AppLayout() {
  const location = useLocation();

  useCheckAppVersion();
  const isPublicRoute = () => {
    return [
      getDashboardRoutesData().sessionLogin,
      getDashboardRoutesData().sessionResetPassword,
    ].includes(location.pathname);
  };
  const props = useLoaderData() as Record<string, boolean>;

  return (
    <DrawerContextProvider>
      {isPublicRoute() || props.isAuthenticated ? (
        <Outlet />
      ) : (
        <div className="flex h-[100vh] items-center justify-center overflow-y-auto bg-neutral-lighter font-base mobile:!max-h-[100vh]">
          <EmptyState type="NOT_FOUND" />
        </div>
      )}
    </DrawerContextProvider>
  );
}

export const routes: RouteObject[] = [
  {
    path: getDashboardRoutesData().dashboard,
    Component: AppLayout,
    loader: async () => {
      return { isAuthenticated: await isAuthenticated() };
    },
    children: [
      {
        index: true,
        Component: Dashboard,
        loader: async () => {
          const { isMfaEnabled, accessToken } = await fetchAuthSession();
          const vertical = getVertical();
          if (vertical === "hyperlocal") {
            if (!accessToken || isMfaEnabled === "false") {
              return redirect(getDashboardRoutesData().sessionLogin);
            }

            return null;
          }

          setHeader({
            show: true,
          });
          return null;
        },
      },
      {
        path: getDashboardRoutesData().sessionLogin,
        element: (
          <LoginProvider defaultRoutes={dashboardRoutes}>
            <Login />
          </LoginProvider>
        ),
        loader: async () => {
          const accountId = useAccountStore.getState().currentAccountId;
          const hasAccountId = Boolean(
            accountId && accountId === getVerticalAccountId(),
          );
          const session = await fetchAuthSession();
          if (session.accessToken && hasAccountId) {
            return redirect(getDashboardRoutesData().dashboard);
          }
          setHeader({
            show: false,
          });
          return null;
        },
      },
      {
        path: getDashboardRoutesData().sessionResetPassword,
        loader: () => {
          const accessTokenKey = getAccessToken();
          const accountId = useAccountStore.getState().currentAccountId;
          const hasAccountId = Boolean(
            accountId && accountId === getVerticalAccountId(),
          );

          if (accessTokenKey || hasAccountId) {
            return redirect(getDashboardRoutesData().dashboard);
          }

          setHeader({
            show: false,
          });
          return null;
        },
        element: (
          <ResetPasswordProvider>
            <ResetPassword />
          </ResetPasswordProvider>
        ),
      },
      {
        path: getDashboardRoutesData().dashboardPay,
        Component: DashboardPay,
      },
      {
        path: getDashboardRoutesData().dashboardPayAnticipation,
        Component: DashboardPayAnticipationPage,
      },
      {
        path: getDashboardRoutesData().settings,
        Component: Settings,
      },
      {
        path: getDashboardRoutesData().settingsNewAccess,
        Component: NewAccess,
      },
      {
        path: getDashboardRoutesData().contracts,
        Component: Contracts,
      },
      {
        path: getDashboardRoutesData().pendingTransactions,
        element: (
          <ProtectedRoute
            allowedRoles={["Proprietário", "Administrador", "Moderador"]}
          >
            <PendingTransactions />
          </ProtectedRoute>
        ),
      },
      {
        path: getDashboardRoutesData().pendingTransactionsDetails,
        element: (
          <ProtectedRoute allowedRoles={["Proprietário", "Administrador"]}>
            <PendingTransactionsDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: `${getDashboardRoutesData().statements}/*`,
        element: (
          <Parcel
            config={() => System.import("@hyperlocal/banking-statements")}
            mountParcel={mountRootParcel}
          />
        ),
      },
      {
        path: `${getDashboardRoutesData().pixDashboard}/*`,
        element: (
          <Parcel
            config={() => System.import("@hyperlocal/banking-pix")}
            mountParcel={mountRootParcel}
          />
        ),
      },
      {
        path: `${getDashboardRoutesData().transfers}/*`,
        element: (
          <Parcel
            config={() => System.import("@hyperlocal/banking-transfers")}
            mountParcel={mountRootParcel}
            wrapStyle={{
              height: "100%",
              overflow: "hidden",
            }}
          />
        ),
      },
      {
        path:
          getDashboardRoutesData().cashOutBoletoCopiaCola ||
          getDashboardRoutesData().cashOutPixCopiaCola,
        element: (
          <Parcel
            config={() => System.import("@hyperlocal/banking-cash-out")}
            mountParcel={mountRootParcel}
          />
        ),
      },
      {
        path: getDashboardRoutesData().salesReport,
        element: (
          <Parcel
            config={() => System.import("@hyperlocal/acquiring-sales")}
            mountParcel={mountRootParcel}
          />
        ),
      },
      {
        path:
          getDashboardRoutesData().received ||
          getDashboardRoutesData().futureReceipts ||
          getDashboardRoutesData().futureReceiptsCalendar,
        element: (
          <Parcel
            config={() => System.import("@hyperlocal/acquiring-received")}
            mountParcel={mountRootParcel}
          />
        ),
      },
    ],
  },
  {
    path: "/politicas-de-privacidade", // TODO: change to dynamic route
    Component: PrivacyPolicies,
  },
  {
    path: "/",
    element: <Navigate to={getDashboardRoutesData().dashboard} />,
  },
];
